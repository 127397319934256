<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <a-form-model ref="newsCategoryForm" :model="form" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol"
        :class="{ disabled: form.status == 2 }">
        <table class="formtable">
          <tr>
            <td colspan="6" style="font-size: 30px">年度预算</td>
          </tr>
          <tr>
            <td style="width: 150px">项目编码及名称</td>
            <td style="text-align: left">
              <a-form-model-item label="" prop="number" style="margin-bottom: 0px">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </td>
            <td style="width: 100px">预算年度</td>
            <td style="text-align: left">
              <a-form-model-item label="" prop="year" style="margin-bottom: 0px">
                <a-input v-model="form.year" />
              </a-form-model-item>
            </td>
            <td>金额单位</td>
            <td style="text-align: left">
              <a-form-model-item label="" prop="unit" style="margin-bottom: 0px">
                <a-input v-model="form.unit" />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td colspan="6" style="padding: 0px">
              <div style="padding: 15px; text-align: left">
                <a-button type="primary" v-if="form.status == 0 || form.status == 3" style="margin-right: 10px"
                  @click="addItem">
                  <a-icon type="plus" />
                  新增</a-button>
                <a-button type="danger" v-if="form.status == 0 || form.status == 3" @click="removeItem">
                  <a-icon type="delete" />
                  删除</a-button>
              </div>
              <a-table bordered :rowKey="(record, index) => {
                return index;
              }
                " :rowSelection="{
                  type: 'checkbox',
                  columnWidth: 20,
                  onChange: onRowChange,
                  selectedRowKeys: selectRows,
                  getCheckboxProps: (record) => ({
                    props: {
                      disabled: record.flag,
                    },
                  }),
                }" :columns="columns" :data-source="form.items" :pagination="false" :locale="{ emptyText: '暂无数据' }">
                <span slot="textbox" slot-scope="text, record, index, column">
                  <a-input v-if="!record.flag" v-model="record[column.dataIndex]" />
                </span>
                <span slot="number" slot-scope="text, record, index, column">
                  <a-input-number v-if="!record.flag" v-model="record[column.dataIndex]"
                    @change="caltotal(column.dataIndex)" />
                  <div v-else style="text-align: left; color: red">
                    {{ record[column.dataIndex] }}
                  </div>
                </span>
              </a-table>
            </td>
          </tr>
        </table>
      </a-form-model>
      <div style="text-align: center;margin-top: 10px;">
        <a-button v-if="form.status == 0 || form.status == 3" type="primary" @click="handleSubmit(0)"
          style="margin-right: 10px">保存</a-button>
        <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="handleSubmit(1)" v-if="form.status == 0 || form.status == 3">
          <a-button type="danger" style="margin-right: 10px">提交</a-button>
        </a-popconfirm>
        <a-button type="primary" v-if="form.status == 1 && modal" style="margin-right: 10px"
          @click="showaudit()">审批</a-button>
        <a-button type="default" v-if="form.status == 2" @click="showWps" style="margin-right: 10px"
          class="cancelBtn">打印</a-button>
        <a-button type="default" @click="cancelClick" class="cancelBtn">取消</a-button>
      </div>
    </div>

    <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose :maskClosable="false">
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </a-spin>
</template>
<script>
  import annualbudgetApi from "@/api/work/annualbudget";
  import pinyin from "js-pinyin";

  const columns = [
    {
      key: "index",
      align: "center",
      title: "序号",
      width: 70,
      customRender: (text, record, index) => {
        return record.flag ? "合计" : index + 1;
      },
    },
    {
      align: "center",
      dataIndex: "name",
      title: "细目名称",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      dataIndex: "zhichuNumber",
      title: "支出功能分类科目编码及名称",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      dataIndex: "bumenNumber",
      title: "部门经济分类科目编码及名称",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      dataIndex: "zhengfuNumber",
      title: "政府经济分类科目编码及名称",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      title: "支出管理方式",
      dataIndex: "expendManagement",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      title: "资金性质",
      dataIndex: "nature",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      title: "资金来源",
      dataIndex: "source",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      title: "预算参考数",
      dataIndex: "cankao",
      scopedSlots: { customRender: "number" },
    },
    {
      align: "center",
      title: "单位申报数",
      dataIndex: "shenbao",
      scopedSlots: { customRender: "number" },
    },

    {
      align: "center",
      title: "上级补助项目代码",
      dataIndex: "shangjiNumber",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      dataIndex: "shangjiName",
      title: "上级补助项目名称",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      title: "文号",
      dataIndex: "wenhao",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      dataIndex: "sanbao",
      title: "三保标识",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      dataIndex: "gaoXiaoYeWu",
      title: "是否省属高校业务性会议费",
      scopedSlots: { customRender: "textbox" },
    },
  ];
  export default {
    name: "annualbudget",
    data() {
      return {
        loading: false,
        form: {
          status: 0,
          items: [
            {},
            {
              flag: true,
            },
          ],
        },
        columns,
        rules: {
          number: [
            {
              required: true,
              message: "请输入项目编码及名称",
              trigger: "blur",
            },
          ],
          year: [
            {
              required: true,
              message: "请输入预算年度",
              trigger: "blur",
            },
          ],
          unit: [
            {
              required: true,
              message: "请输入金额单位",
              trigger: "blur",
            },
          ],
        },
        labelCol: { span: 5 },
        wrapperCol: { span: 23 },
        auditModal: {
          info: null,
          visible: false,
        },
        selectRows: [],
        dataList: [],
      };
    },
    props: {
      info: {
        type: Object,
      },
      modal: {
        type: String,
        defalut: "",
      },
    },
    mounted() {
      if (this.info && this.info.id > 0) {
        this.getData();
      } else {
        this.form = {
          status: 0,
          items: [
            {},
            {
              flag: true,
            },
          ],
        }
        /* var item = JSON.parse(localStorage.getItem("annualbudget"));
        var flag = false;
        for (var key in item) {
          if (key == "number") {
            flag = true;
            break;
          }
        }
        if (flag) {
          this.form = item;
        } else {
          var params = this.$route.params;
          if (params && params.status != null) {
            this.form = params;
          } else {
            this.getLast();
          }
        } */
      }
    },
    watch: {
      form: {
        handler(newValue) {
          if (newValue.id == null || newValue.id == 0)
            localStorage.setItem("annualbudget", JSON.stringify(newValue));
        },
        deep: true,
      },
    },
    methods: {
      getLast() {
        annualbudgetApi.getLast().then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.items.push({
              flag: true,
            });
            this.form = res.data;
            this.caltotal("cankao");
            this.caltotal("shenbao");
          }
        });
      },
      createnew() {
        this.form = {
          status: 0,
          items: [
            {},
            {
              flag: true,
            },
          ],
        };
        localStorage.removeItem("annualbudget");
      },
      caltotal(c) {
        var total = 0;
        for (var i = 0; i < this.form.items.length - 1; ++i) {
          total += this.form.items[i][c] ? Number(this.form.items[i][c]) : 0;
        }
        this.form.items[this.form.items.length - 1][c] = total.toString().indexOf('.') > -1 ? total.toFixed(6) : total;
        this.$forceUpdate();
      },
      onRowChange(selectedRowKeys) {
        this.selectRows = selectedRowKeys;
      },
      showaudit() {
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      auditCallBack(data) {
        this.auditModal.visible = false;
        this.$emit("callback", data != null);
      },
      addItem() {
        this.form.items.splice(this.form.items.length - 1, 0, {});
      },
      removeItem() {
        var indexArr = this.selectRows;
        this.form.items = this.form.items.filter(
          (item, index) => indexArr.filter((i) => i === index).length == 0
        );
        this.selectRows = [];
      },
      getData() {
        this.loading = true;
        annualbudgetApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            if (res.data.items == null) {
              res.data.items = [{}];
            } else {
              this.dataList = Object.assign([], res.data.items);
            }
            res.data.items.push({ flag: true });
            this.form = res.data;
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            this.caltotal("cankao");
            this.caltotal("shenbao");
          }
        });
      },
      showWps() {
        let templateId = "annualbudget";
        let innerData = Object.assign({}, this.form);
        delete innerData.auditRecords;
        delete innerData.processExecuteStatus;
        delete innerData.processId;
        delete innerData.processModelKey;
        delete innerData.processStatus;
        delete innerData.taskId;
        delete innerData.taskKey;
        delete innerData.taskRoles;
        delete innerData.taskUsers;
        delete innerData.status;
        delete innerData.creatorOrgId;

        if (this.dataList != null && this.dataList.length > 0) {
          let i = 0;
          this.dataList.forEach((a) => {
            a.sort = ++i;
          });
          delete innerData.items;
          innerData.list = this.dataList;
        }
        this.form.auditRecords.forEach((a) => {
          let key =
            "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
          if (a.taskPrintKey != null) {
            key = "taskKey" + a.taskPrintKey;
          }
          if (innerData[key] == null) {
            innerData[key] = [];
          }
          let auditPic = "";
          if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
            let s = a.auditUserSignPicture.indexOf("uas");
            auditPic =
              "http://localhost:9000/" +
              a.auditUserSignPicture.substring(
                a.auditUserSignPicture.indexOf("uas")
              );
          }
          let task = {
            auditRemark: a.auditRemark,
            auditPic: auditPic,
            auditName: a.auditUserName,
            auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            ),
          };
          innerData[key].push(task);
        });
        localStorage.setItem("innerData", JSON.stringify(innerData));
        localStorage.removeItem("publicFileId");
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: "create",
            fileName: "年度预算",
            tempUrl: "writeNumberTemp",
          },
        });
        window.open(jump.href, "_blank");
      },
      handleSubmit(status) {
        this.$refs.newsCategoryForm.validate((valid) => {
          if (valid) {
            let formData = { ...this.form, status };
            formData.items = [].concat(this.form.items);
            this.loading = true;
            if (this.info) {
              formData.id = this.info.id;
            }
            delete formData.createTime;
            delete formData.creator;
            delete formData.creatorName;
            delete formData.auditRecords;
            if (formData.items) {
              var total = 0;
              formData.items.splice(formData.items.length - 1, 1);
              formData.items.forEach((item) => {
                total += item.shenbao;
              });
              formData.amount = total;
            }
            //添加
            annualbudgetApi
              .addOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == this.$commons.RespCode.success) {
                  this.$message.success(res.errorMsg);
                  localStorage.removeItem("annualbudget");
                  this.$emit("callback", true);
                } else {
                  this.$message.error(res.errorMsg);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      },
      cancelClick() {
        this.$emit("callback", false);
      },
    },
  };
</script>
<style>
  .ant-table-selection-column {
    width: 20px;
    padding: 0px;
  }
</style>